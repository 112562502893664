<template>
  <div ref="container" class="nebula-cover fit" :class="{ hyperspace: hyperspace }">
    <!-- Parallax js requires a DOM object with a defined size in order to calculate its velocity -->
    <div ref="dummyParallax" class="fit"></div>
    <div ref="scene" class="stars-scene" :style="sceneStyles">
      <div class="wrap">
        <div class="wall wall-right"></div>
        <div class="wall wall-left"></div>
        <div class="wall wall-top"></div>
        <div class="wall wall-bottom"></div>
        <!--<div class="wall wall-back"></div>-->
      </div>
      <div class="wrap">
        <div class="wall wall-right"></div>
        <div class="wall wall-left"></div>
        <div class="wall wall-top"></div>
        <div class="wall wall-bottom"></div>
        <!--<div class="wall wall-back"></div>-->
      </div>
    </div>

    <transition name="fade">
      <div class="mobile-info text-white" v-if="displayMobileInfo">
        <i class="bi bi-hand-index"></i> {{ mobileInfo }}
      </div>
    </transition>
  </div>
</template>

<script>
import Nebula from '@/img/cover/nebula.webp';
import NebulaMap from '@/img/cover/nebula-map.webp';
import * as PIXI from '@/js/pixi.js';
import {IS_MOBILE} from "@/js/const";
import Parallax from 'parallax-js';
import debounce from 'lodash-es/debounce';

const nebulaWidth = 1837;
const nebulaHeight = 1687;
const xRatio = nebulaWidth / nebulaHeight;
const widthIncrement = IS_MOBILE ? 30 : 0; // Used to increase the canvas width a bit when the nebula is in the border

export default {
  props: {
    hyperspace: {
      type: Boolean,
      default: false
    },
    mobileInfo: {
      type: String
    }
  },
  data() {
    return {
      pixiApp: null,
      nebulaSprite: null,
      nebulaDepth: null,
      displayMobileInfo: false,
      loadedOrientationDetection: false,
      sceneStyles: {
        top: '50%',
        right: '28%'
      }
    }
  },
  mounted() {
    let pixiOptions = {resizeTo: window, backgroundAlpha: 0};
    // Resize only on desktop, because this is expensive for mobile
    if (IS_MOBILE) {
      pixiOptions = {width: window.innerWidth + widthIncrement, height: window.innerHeight, backgroundAlpha: 0};
    }
    this.pixiApp = new PIXI.Application(pixiOptions);
    this.$refs.container.prepend(this.pixiApp.view);

    this.nebulaSprite = new PIXI.Sprite.from(Nebula);
    this.configNebulaSprite(this.nebulaSprite);
    this.pixiApp.stage.addChild(this.nebulaSprite);

    this.nebulaDepth = new PIXI.Sprite.from(NebulaMap);
    this.configNebulaSprite(this.nebulaDepth);
    this.pixiApp.stage.addChild(this.nebulaDepth);

    let displacementFilter = new PIXI.filters.DisplacementFilter(this.nebulaDepth);
    this.pixiApp.stage.filters = [displacementFilter];

    // The displacement filter is passed to both functions because otherwise a graphic glitch happens if we save it in data
    if (IS_MOBILE) {
      this.initializeForMobile(displacementFilter);
    } else {
      this.initializeForDesktop(displacementFilter);
    }
  },
  methods: {
    onResize() {
      this.configNebulaSprite(this.nebulaSprite);
      this.configNebulaSprite(this.nebulaDepth);
    },
    configNebulaSprite(sprite) {
      sprite.height = this.pixiApp.screen.height * 1.2;
      sprite.width = this.pixiApp.screen.height * 1.2 * xRatio;
      // The anchor is the point of the image that's placed in the position,
      // in this case the anchor begins at x: 100%, y: 10% (The upper right corner)
      let xAnchor = 1;
      const yAnchor = 0.1;
      if (this.pixiApp.screen.width < 1200) {
        // We slowly move the anchor of the image to the center of the nebula, that way the anchor would be x: 50% when
        // the width of the device is 300px
        xAnchor = xAnchor - (300 / this.pixiApp.screen.width * (xAnchor - 0.5));
      }
      sprite.anchor.set(xAnchor, yAnchor);
      sprite.x = this.pixiApp.screen.width;

      // Locate the stars scene in the center of the nebula
      this.sceneStyles.top = `${sprite.height * (1 - yAnchor) / 2}px`;
      // If the anchor is 1, the center of the nebula is in right corner - half of the sprite width
      // If the anchor is 0.5, the center of the nebula is at the right corner
      this.sceneStyles.right = `${(xAnchor - 0.5) * sprite.width - 45 - widthIncrement}px`;
    },
    initializeForDesktop(displacementFilter) {
      // Parallax JS uses mouse movements on Desktop, its behavior is quite superior to just reading the mousemove
      // ourselves because it can calculate inertia and velocity, which causes smoother movements even when the cursor
      // gets out of the screen
      const parallaxInstance = new Parallax(this.$refs.dummyParallax);
      setInterval(() => {
        requestAnimationFrame(() => {
          displacementFilter.scale.x = -parallaxInstance.velocityX / 3;
          displacementFilter.scale.y = -parallaxInstance.velocityY / 3;
        });
      }, 40);

      window.addEventListener('resize', debounce(this.onResize.bind(this), 100));
    },
    initializeForMobile(displacementFilter) {
      // Set the displacement filter to 0 to avoid an initial glitch
      this.displayMobileInfo = true;
      displacementFilter.scale.x = 0;
      displacementFilter.scale.y = 0;
      try {
        this.enableOrientationDetection(displacementFilter);
      } catch {
        // This failed, the user hasn't allowed the permission, an user interaction is mandatory,
        // then try again after a click
      }
      this.$refs.container.addEventListener("click", () => {
        this.displayMobileInfo = false;
        if (!this.loadedOrientationDetection) {
          try {
            this.enableOrientationDetection(displacementFilter);
          } catch {
            // We tried twice to enable this, even after a click, there is nothing else we can do
          }
        } else {
          // Enable mobile hyperspace on click
          this.$root.home.hyperspace = !this.$root.home.hyperspace;
        }
      });
    },
    async enableOrientationDetection(displacementFilter) {
      if (window.DeviceOrientationEvent) {
        // This is required in iOS 13+
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
          await DeviceOrientationEvent.requestPermission();
          this.addOrientationListener(displacementFilter);
        } else {
          // For other devices the permissions may not be needed
          this.addOrientationListener(displacementFilter);
        }
      }
    },
    addOrientationListener(displacementFilter) {
      // Parallax js uses deviceorientation and devicemotion for mobile, however, it doesn't work on modern iOS
      // because iOS request an user permission that must be triggered the first time by an user interaction.
      // This validates that the deviceorientation event actually works, then we can safely initialize parallax js
      // because there might be devices that even trigger this event, but without coordinates, and we don't
      // want parallax js to use the mouse fallback for mobile
      const deviceOrientationCallbackOnce = (e) => {
        window.removeEventListener("deviceorientation", deviceOrientationCallbackOnce);
        if (e.gamma !== null && e.beta !== null) {
          this.loadMobileParallax(displacementFilter);
        }
      };
      window.addEventListener("deviceorientation", deviceOrientationCallbackOnce);
      this.loadedOrientationDetection = true;
    },
    loadMobileParallax(displacementFilter) {
      // This uses device orientation, which have an Euler form, which have the Gimbal lock disadvantage, therefore
      // an improvement might be using Quaternions to avoid Gimbal Lock, see: https://en.wikipedia.org/wiki/Gimbal_lock
      // however, I tried using quaternions with the quaternion library without success, because the jum of the beta
      // angle from -180 to 179 and the gamma angle from -90 to 89 causes the same flickering issue as without quaternions.
      const parallaxInstance = new Parallax(this.$refs.dummyParallax);
      parallaxInstance.calibrate(true, true);
      parallaxInstance.limit(40, 50); // Limit velocities
      setInterval(() => {
        requestAnimationFrame(() => {
          displacementFilter.scale.x = -parallaxInstance.velocityX;
          displacementFilter.scale.y = -parallaxInstance.velocityY + 10;
        });
      }, 50);
    },
  }
}
</script>

<template>
  <transition name="fade" mode="out-in">
    <span v-if="show1">{{ text1 }}</span>
    <span v-else>{{ text2 }}</span>
  </transition>
</template>

<script>
export default {
  name: "SwitchableText",
  props: {
    show1: {
      type: Boolean,
      default: true
    },
    text1: {
      type: String,
      required: true
    },
    text2: {
      type: String,
      required: true
    }
  }
}
</script>

import '../scss/app.scss';
import ScrollReveal from 'scrollreveal';
import Rellax from 'rellax';
// esm-bundlers includes the compiler, the compiler is needed for in-DOM templates or templates via inline JavaScript strings
// See: https://v3.vuejs.org/guide/installation.html#explanation-of-different-builds
import { createApp } from 'vue/dist/vue.esm-bundler';
import NebulaCover from '@/js/components/NebulaCover.vue';
import SwitchableText from "@/js/components/SwitchableText";
import { IS_MOBILE } from "@/js/const";

const Root = {
  delimiters: ['[[', ']]'],
  data() {
    return {
      home: {
        hyperspace: false,
      }
    }
  },
  mounted() {
    new Rellax('.rellax');
    ScrollReveal().reveal('.reveal', { delay: 500, interval: 100, duration: 1000, distance: '25px' });
    ScrollReveal().reveal('.reveal-right', { delay: 200, interval: 200, duration: 1500, distance: '25px', origin: 'right'});
  },
  methods: {
    homeOnOverCTA() {
      if (!IS_MOBILE) {
        this.home.hyperspace = true;
      }
    },
    homeOnLeaveCTA() {
      if (!IS_MOBILE) {
        this.home.hyperspace = false;
      }
    }
  }
};

const app = createApp(Root);

// Components registration
app.component('nebula-cover', NebulaCover);
app.component('switchable-text', SwitchableText);

app.mount('#app');
